const AWS = require('aws-sdk')
const getError = (option, xhr) => {
  const msg = `cannot post ${option.action} ${xhr.status}'`;
  const err = new Error(msg);
  err.status = xhr.status;
  err.method = 'post';
  err.url = option.action;
  return err;
}

const getBody = (xhr) => {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text;
  }
  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
}

// option {
//  onProgress: (event: { percent: number }): void,
//  onError: (event: Error, body?: Object): void,
//  onSuccess: (body: Object): void,
//  data: Object,
//  filename: String,
//  file: File,
//  withCredentials: Boolean,
//  action: String,
//  headers: Object,
// }
const postToS3 = (option) => {
  const xhr = new XMLHttpRequest();
  if (option.onProgress && xhr.upload) {
    xhr.upload.onprogress = (e) => {
      if (e.total > 0) {
        e.percent = e.loaded / e.total * 100;
      }
      option.onProgress(e);
    };
  }
  const formData = new FormData();
  if (option.data) {
    Object.keys(option.data).map((key) => {
      formData.append(key, option.data[key]);
    });
  }
  formData.append('file', option.file);
  xhr.onerror = (e) => {
    // option.file.percent = 100
    option.onError(e);
  };
  xhr.onload = () => {
    // allow success when 2xx status
    // see https://github.com/react-component/upload/issues/34
    // option.file.percent = 100
    if (xhr.status < 200 || xhr.status >= 300) {
      return option.onError(getError(option, xhr), getBody(xhr));
    }
    option.onSuccess(getBody(xhr));
  };
  xhr.open('post', option.file.signUrl, true);
  // Has to be after `.open()`. See https://github.com/enyo/dropzone/issues/179
  if (option.withCredentials && 'withCredentials' in xhr) {
    xhr.withCredentials = true;
  }
  const headers = option.headers || {};
  // when set headers['X-Requested-With'] = null , can close default XHR header
  // see https://github.com/react-component/upload/issues/33
  if (headers['X-Requested-With'] !== null) {
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  }
  for (const h in headers) {
    if (headers.hasOwnProperty(h) && headers[h] !== null) {
      xhr.setRequestHeader(h, headers[h]);
    }
  }
  xhr.send(formData);
  return {
    abort () {
      xhr.abort();
    }
  };
}

const putToS3 = (option) => {
  const xhr = new XMLHttpRequest();
  if (option.onProgress && xhr.upload) {
    xhr.upload.onprogress = (e) => {
      if (e.total > 0) {
        e.percent = e.loaded / e.total * 100;
      }
      option.onProgress(e);
    };
  }
  xhr.onerror = (e) => {
    // option.file.percent = 100
    option.onError(e);
  };
  xhr.onload = () => {
    // allow success when 2xx status
    // see https://github.com/react-component/upload/issues/34
    // option.file.percent = 100
    if (xhr.status < 200 || xhr.status >= 300) {
      return option.onError(getError(option, xhr), getBody(xhr));
    }

    option.onSuccess(getBody(xhr));
  };
  xhr.open('PUT', option.file.signUrl, true);
  xhr.send(option.file);
  return {
    abort () {
      xhr.abort();
    }
  };
}



module.exports = {
  putToS3,
  postToS3
}

